import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import './styles/styles.scss'

import App from './components/App/App'

import * as serviceWorker from './serviceWorker'

// axios config
axios.defaults.baseURL = 'https://api.foodprint.app'
axios.defaults.headers.post['Content-Type'] = 'application/json'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
